import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import PlayerList from './PlayerList'
import DiceRoll from './DiceRoll'
import ScoreBoard from './ScoreBoard'

function Game() {
  const [players, setPlayers] = useState([])
  const [currentPlayerIndex, setCurrentPlayerIndex] = useState(0)
  const [roundScores, setRoundScores] = useState({})
  const [roundsWon, setRoundsWon] = useState({})
  const [gameStarted, setGameStarted] = useState(false)
  const [turnScore, setTurnScore] = useState(0)
  const [shouldResetRound, setShouldResetRound] = useState(false)
  const [shouldResetDice, setShouldResetDice] = useState(false)
  const [lastRoundWinner, setLastRoundWinner] = useState(null)
  const [playersThisRound, setPlayersThisRound] = useState(new Set())

  useEffect(() => {
    if (shouldResetRound) {
      const resetScores = {}
      players.forEach(player => {
        resetScores[player] = 0
      })
      setRoundScores(resetScores)
      
      // If there's a last round winner, find their index to start with
      if (lastRoundWinner) {
        const winnerIndex = players.findIndex(player => player === lastRoundWinner)
        setCurrentPlayerIndex(winnerIndex)
      } else {
        setCurrentPlayerIndex(0)
      }
      
      setTurnScore(0)
      setPlayersThisRound(new Set())
      setShouldResetRound(false)
      setShouldResetDice(true)
    }
  }, [shouldResetRound, players, lastRoundWinner])

  const addPlayer = (name) => {
    if (name && !players.includes(name)) {
      setPlayers([...players, name])
      setRoundScores({ ...roundScores, [name]: 0 })
      setRoundsWon({ ...roundsWon, [name]: 0 })
    }
  }

  const startGame = () => {
    if (players.length >= 2) {
      setGameStarted(true)
    } else {
      alert('You need at least 2 players to start the game.')
    }
  }

  const updateScore = (newScore) => {
    setTurnScore(newScore)
  }

  const endTurn = () => {
    const currentPlayer = players[currentPlayerIndex]
    
    // Add current player to the set of players who have gone this round
    const updatedPlayersThisRound = new Set(playersThisRound)
    updatedPlayersThisRound.add(currentPlayer)
    setPlayersThisRound(updatedPlayersThisRound)
    
    // Create new round scores with the current turn score
    const newRoundScores = {
      ...roundScores,
      [currentPlayer]: turnScore
    }
    
    // Check if all players have gone this round
    const isRoundComplete = updatedPlayersThisRound.size === players.length
    
    if (isRoundComplete) {
      // Find winner using the new round scores
      findRoundWinner(newRoundScores)
      // Set flag to reset round
      setShouldResetRound(true)
    } else {
      // Set the round score and move to next player
      setRoundScores(newRoundScores)
      // Move to next player, wrapping around to start if needed
      const nextIndex = (currentPlayerIndex + 1) % players.length
      setCurrentPlayerIndex(nextIndex)
      setTurnScore(0)
    }
  }

  const findRoundWinner = (finalRoundScores) => {
    let lowestScore = Infinity
    let winners = []
    
    // Find the lowest score
    Object.entries(finalRoundScores).forEach(([player, score]) => {
      if (score < lowestScore) {
        lowestScore = score
        winners = [player]
      } else if (score === lowestScore) {
        winners.push(player)
      }
    })

    // Update rounds won for winner(s)
    const newRoundsWon = { ...roundsWon }
    winners.forEach(winner => {
      newRoundsWon[winner] = (newRoundsWon[winner] || 0) + 1
    })
    setRoundsWon(newRoundsWon)

    // Set the last round winner
    setLastRoundWinner(winners[0])

    // Announce winner(s)
    if (winners.length === 1) {
      alert(`${winners[0]} wins the round with a score of ${lowestScore}! They will start the next round.`)
    } else {
      alert(`Tie between ${winners.join(' and ')} with a score of ${lowestScore}! ${winners[0]} will start the next round.`)
    }
  }

  return (
    <Container className="mt-4">
      <h1 className="mb-4">Rollin' 3s</h1>
      {!gameStarted ? (
        <PlayerList players={players} addPlayer={addPlayer} startGame={startGame} />
      ) : (
        <Row>
          <Col md={6}>
            <ScoreBoard 
              players={players} 
              roundScores={roundScores}
              roundsWon={roundsWon}
              currentPlayerIndex={currentPlayerIndex} 
              turnScore={turnScore}
            />
          </Col>
          <Col md={6}>
            <DiceRoll 
              updateScore={updateScore} 
              endTurn={endTurn}
              shouldResetDice={shouldResetDice}
              onDiceReset={() => setShouldResetDice(false)}
            />
          </Col>
        </Row>
      )}
    </Container>
  )
}

export default Game
