import { collection, query, where, getDocs, doc, getDoc, updateDoc, deleteDoc, arrayRemove, serverTimestamp, setDoc } from 'firebase/firestore';
import { db } from '../firebase/config';
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { setPersistence, browserLocalPersistence } from 'firebase/auth';

const auth = getAuth();

setPersistence(auth, browserLocalPersistence)
  .catch((error) => {
    console.error("Auth persistence error:", error);
  });

/**
 * Fetches all groups where the user is a member
 */
export const fetchUserGroups = async (userId) => {
  if (!userId) {
    throw new Error('User ID is required');
  }

  try {
    const groupsRef = collection(db, 'groups');
    const allGroupsSnapshot = await getDocs(groupsRef);
    
    // Filter groups client-side where the user is a member
    const userGroups = allGroupsSnapshot.docs
      .filter(doc => {
        const data = doc.data();
        return data.users.some(u => 
          (u.user?.id === userId || // Check direct ID
           u.user?._key?.path?.segments?.includes(userId)) // Check reference path
        );
      })
      .map(doc => {
        const data = doc.data();
        // Find the user's role in the group
        const userInGroup = data.users.find(u => 
          (u.user?.id === userId || 
           u.user?._key?.path?.segments?.includes(userId))
        );
        
        return {
          id: doc.id,
          label: data.name,
          created_at: data.created_at,
          items: data.items || [],
          users: data.users || [],
          name: data.name,
          name_lower: data.name_lower,
          updated_at: data.updated_at,
          isAdmin: userInGroup?.role === 'admin', // Add isAdmin check
          active: data.active
        };
      })
      .sort((a, b) => {
        const dateA = a.created_at?.toDate?.() || new Date(a.created_at);
        const dateB = b.created_at?.toDate?.() || new Date(b.created_at);
        return dateB - dateA;
      });

    return userGroups;
  } catch (error) {
    console.error('Error fetching user groups:', error);
    throw error;
  }
};

/**
 * Fetches members of a specific group
 */
export const fetchGroupMembers = async (group) => {
  if (!group?.users) return [];
  
  const members = [];
  for (const userRef of group.users) {
    try {
      const memberDoc = await getDoc(doc(db, 'users', userRef.user.id));
      if (memberDoc.exists()) {
        members.push({
          ...memberDoc.data(),
          id: userRef.user.id,
          role: userRef.role
        });
      }
    } catch (error) {
      console.error("Error fetching member:", error);
    }
  }
  return members;
};

/**
 * Removes a user from a group
 */
export const removeUserFromGroup = async (groupId, userId) => {
  try {
    const groupRef = doc(db, 'groups', groupId);
    const groupSnap = await getDoc(groupRef);
    
    if (groupSnap.exists()) {
      const groupData = groupSnap.data();
      const userToRemove = groupData.users.find(u => u.user.id === userId);
      
      if (userToRemove) {
        await updateDoc(groupRef, {
          users: arrayRemove(userToRemove),
          updated_at: serverTimestamp()
        });
        return true;
      }
    }
    return false;
  } catch (error) {
    console.error("Error removing user from group:", error);
    throw error;
  }
};

/**
 * Deletes a group entirely
 */
export const deleteGroup = async (groupId) => {
  try {
    const groupRef = doc(db, 'groups', groupId);
    await deleteDoc(groupRef);
    return true;
  } catch (error) {
    console.error("Error deleting group:", error);
    throw error;
  }
};

/**
 * Searches for a group by exact name
 */
export const searchGroupByName = async (groupName) => {
  try {
    const q = query(
      collection(db, 'groups'), 
      where('name', '==', groupName.trim())
    );
    const querySnapshot = await getDocs(q);
    
    if (!querySnapshot.empty) {
      const groupDoc = querySnapshot.docs[0];
      const data = groupDoc.data();
      return {
        id: groupDoc.id,
        name: data.name,
        items: data.items || [],
        users: data.users || [],
        created_at: data.created_at,
        updated_at: data.updated_at,
        name_lower: data.name_lower
      };
    }
    return null;
  } catch (error) {
    console.error('Error searching for group:', error);
    throw error;
  }
};

/**
 * Checks if a group name already exists
 */
export const checkGroupNameExists = async (name) => {
  try {
    const lowercaseName = name.trim().toLowerCase();
    const q = query(
      collection(db, 'groups'),
      where('name_lower', '==', lowercaseName)
    );
    
    const querySnapshot = await getDocs(q);
    return querySnapshot.size > 0; // Return true if any groups exist with this name
  } catch (error) {
    console.error('Error checking group name:', error);
    throw error;
  }
};

/**
 * Creates a new group with initial items
 */
export const createGroup = async (groupName, items, userId) => {
  if (!userId) {
    throw new Error('User ID is required to create a group');
  }

  try {
    const lowercaseName = groupName.trim().toLowerCase();
    
    // First check if group name exists
    const groupExists = await checkGroupNameExists(groupName);
    if (groupExists) {
      throw new Error('A group with this name already exists');
    }

    const groupRef = doc(collection(db, 'groups'));
    
    // Create items with proper references
    const itemsWithRefs = await Promise.all(items.map(async (item) => {
      // Create a new item document reference
      const itemRef = doc(collection(db, 'items'));
      
      // Create the actual item document
      await setDoc(itemRef, {
        name: item.name
      });

      // Return the structure we want in the group
      return {
        name: item.name,
        ref: itemRef
      };
    }));
    
    // Create new group with proper structure
    const newGroup = {
      name: groupName.trim(),
      name_lower: lowercaseName,
      items: itemsWithRefs,
      users: [{
        user: doc(db, 'users', userId),
        role: 'admin',
        values: itemsWithRefs.map(item => ({
          item: item.ref,
          value: 0
        }))
      }],
      created_at: serverTimestamp(),
      updated_at: serverTimestamp()
    };

    await setDoc(groupRef, newGroup);
    return groupRef;
  } catch (error) {
    console.error('Error creating group:', error);
    throw error;
  }
};

/**
 * Signs in with Google
 */
export const signInWithGoogle = async () => {
  try {
    const provider = new GoogleAuthProvider();
    const result = await signInWithPopup(auth, provider);
    return result.user;
  } catch (error) {
    console.error("Google sign-in error:", error);
    throw error;
  }
};

/**
 * Updates the active status of a group
 */
export const updateGroupActiveStatus = async (groupId, isActive) => {
  try {
    const groupRef = doc(db, 'groups', groupId);
    await updateDoc(groupRef, {
      active: isActive,
      updated_at: serverTimestamp()
    });
    return true;
  } catch (error) {
    console.error("Error updating group active status:", error);
    throw error;
  }
};
