import React, { useState, useEffect, useCallback } from 'react'
import { Button, Row, Col, Form } from 'react-bootstrap'
import './DiceRoll.css'

function DiceRoll({ updateScore, endTurn }) {
  const [diceValues, setDiceValues] = useState([])
  const [canRoll, setCanRoll] = useState(true)
  const [currentRollSelections, setCurrentRollSelections] = useState(new Set())
  const [isRolling, setIsRolling] = useState(false)
  const [shouldResetDice, setShouldResetDice] = useState(false)
  const [ezMode, setEzMode] = useState(false)

  useEffect(() => {
    if (shouldResetDice) {
      setDiceValues([])
      setCanRoll(true)
      setCurrentRollSelections(new Set())
      setShouldResetDice(false)
    }
  }, [shouldResetDice])

  const calculateScore = useCallback((diceToScore) => {
    return diceToScore.reduce((sum, die) => 
      sum + (die.value === 3 ? 0 : die.value), 0
    )
  }, [])

  const rollDice = useCallback(() => {
    setIsRolling(true)
    setCurrentRollSelections(new Set())
    
    setTimeout(() => {
      const newDiceValues = diceValues.map(die => 
        die.selected ? die : { value: Math.floor(Math.random() * 6) + 1, selected: false }
      )
      if (newDiceValues.length < 5) {
        const additionalDice = Array.from({ length: 5 - newDiceValues.length }, () => ({
          value: Math.floor(Math.random() * 6) + 1,
          selected: false
        }))
        newDiceValues.push(...additionalDice)
      }
      setDiceValues(newDiceValues)
      setCanRoll(false)
      setIsRolling(false)

      // Update score for all dice after rolling
      const score = calculateScore(newDiceValues)
      updateScore(score)
    }, 800)
  }, [diceValues, calculateScore, updateScore])

  useEffect(() => {
    if (diceValues.length === 0) {
      rollDice()
    }
  }, [diceValues.length, rollDice])

  const toggleDieSelection = (index) => {
    if (!canRoll && !isRolling) {
      const newDiceValues = [...diceValues]
      const newSelected = !newDiceValues[index].selected
      newDiceValues[index].selected = newSelected
      
      const newCurrentRollSelections = new Set(currentRollSelections)
      if (newSelected) {
        newCurrentRollSelections.add(index)
      } else {
        newCurrentRollSelections.delete(index)
      }
      
      setCurrentRollSelections(newCurrentRollSelections)
      setDiceValues(newDiceValues)

      // Update score for all dice after selection
      const score = calculateScore(newDiceValues)
      updateScore(score)
    }
  }

  const handleStay = () => {
    // Select all remaining unselected dice
    const finalDiceValues = diceValues.map(die => ({
      ...die,
      selected: true
    }))
    setDiceValues(finalDiceValues)

    // Calculate final score with all dice
    const finalScore = calculateScore(finalDiceValues)
    updateScore(finalScore)
    endTurn()
    
    setShouldResetDice(true)
  }

  const renderDots = (value) => {
    const dots = [];
    for (let i = 0; i < value; i++) {
      dots.push(<div key={i} className="dot" />);
    }
    return dots;
  }

  const canReRoll = !canRoll && 
                    currentRollSelections.size > 0 && 
                    !diceValues.every(d => d.selected) &&
                    !isRolling

  return (
    <div>
      <div className="text-center mb-3">
        <h4>
          Current Roll Total: {calculateScore(diceValues)}
        </h4>
      </div>
        <Row className="mb-3">
          {diceValues.map((die, index) => (
            <Col key={index} xs={2} className="mb-2">
              <div
                className={`dice-face dice-value-${die.value} ${
                  die.selected ? 'selected' : ''
                } ${!die.selected && isRolling ? 'rolling' : ''} ${
                  ezMode && !die.selected && die.value === 3 ? 'highlight-three' : ''
                }`}
                onClick={() => toggleDieSelection(index)}
              >
                {renderDots(die.value)}
              </div>
            </Col>
          ))}
        </Row>
        <small className="text-muted">
          (Threes count as zero)
        </small>
      <div className="d-flex align-items-center">
        <div className="me-auto">
          <Button 
            variant="primary" 
            onClick={rollDice} 
            disabled={!canReRoll}
            className="me-2 fixed-width-button"
          >
            {isRolling ? 'Rolling...' : 'Re-roll'}
          </Button>
          <Button 
            variant="secondary" 
            onClick={handleStay}
            disabled={isRolling}
          >
            Stay
          </Button>
        </div>
        <Form.Check 
          type="switch"
          id="ez-mode-switch"
          label="EZ Mode"
          checked={ezMode}
          onChange={(e) => setEzMode(e.target.checked)}
        />
      </div>
    </div>
  )
}

export default DiceRoll
