import React, { useContext, useState, useEffect, createContext } from 'react';
import { 
  signInWithPopup, 
  GoogleAuthProvider, 
  signOut as firebaseSignOut 
} from 'firebase/auth';
import { auth } from '../firebase/config';
import { trackEvent } from '../firebase/config';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const signIn = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      
      // Track successful sign-in
      trackEvent('user_login', {
        method: 'google',
        user_id: result.user.uid
      });
      
      return result;
    } catch (error) {
      // Track failed sign-in
      trackEvent('login_error', {
        error_code: error.code,
        error_message: error.message
      });
      throw error;
    }
  };

  const signOut = async () => {
    try {
      if (user) {
        // Track sign-out
        trackEvent('user_logout', {
          user_id: user.uid
        });
      }
      return firebaseSignOut(auth);
    } catch (error) {
      console.error('Sign out error:', error);
      throw error;
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    user,
    signIn,
    signOut,
    loading
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
} 
