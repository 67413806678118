import React, { useState, useEffect } from 'react';
import { checkGroupNameExists } from '../../utils/firebaseUtils';
import './UniqueGroupInput.css';
import { useAuth } from '../../context/AuthContext';

const UniqueGroupInput = ({ onValidityChange, isCreating }) => {
  const { user: authUser } = useAuth();
  const [groupName, setGroupName] = useState('');
  const [newItem, setNewItem] = useState('');
  const [items, setItems] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [itemError, setItemError] = useState('');

  useEffect(() => {
    const isValid = groupName.trim().length >= 3 && 
                   items.length > 0 && 
                   !errorMessage;
    onValidityChange(isValid, groupName.trim(), items);
  }, [groupName, items, errorMessage, onValidityChange]);

  const checkGroupName = async (name) => {
    if (!name.trim() || name.trim().length < 3) {
      setErrorMessage('Group name must be at least 3 characters');
      return false;
    }

    try {
      const exists = await checkGroupNameExists(name, authUser?.uid);
      
      if (exists) {
        setErrorMessage('Group name already exists');
        return false;
      }

      setErrorMessage('');
      return true;
    } catch (error) {
      console.error('Error checking group name:', error);
      setErrorMessage('Error checking group name');
      return false;
    }
  };

  const handleGroupNameChange = (e) => {
    const value = e.target.value;
    setGroupName(value);
    setErrorMessage('');
  };

  const handleAddItem = () => {
    const trimmedItem = newItem.trim();
    
    if (trimmedItem.length === 0) {
      setItemError('Item name cannot be empty');
      return;
    }

    // Check for item limit
    if (items.length >= 3) {
      setItemError('Maximum of 3 items allowed');
      return;
    }

    // Check for duplicate item names (case insensitive)
    if (items.some(item => item.name.toLowerCase() === trimmedItem.toLowerCase())) {
      setItemError('Item name must be unique');
      return;
    }

    setItems([...items, { id: Date.now(), name: trimmedItem }]);
    setNewItem('');
    setItemError('');
  };

  const handleRemoveItem = (itemId) => {
    setItems(items.filter(item => item.id !== itemId));
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleAddItem();
    }
  };

  return (
    <div className="unique-group-input">
      <div className="input-field">
        <input
          type="text"
          value={groupName}
          onChange={handleGroupNameChange}
          onBlur={() => checkGroupName(groupName)}
          placeholder="Enter group name"
          className={`group-name-input ${errorMessage ? 'invalid' : ''}`}
          disabled={isCreating}
        />
        {errorMessage && <span className="error-message">{errorMessage}</span>}
      </div>

      <div className="items-list">
        {items.map((item) => (
          <div key={item.id} className="item-tag">
            <span>{item.name}</span>
            <button
              onClick={() => handleRemoveItem(item.id)}
              className="remove-item"
              disabled={isCreating}
            >
              ×
            </button>
          </div>
        ))}
      </div>

      <div className="item-input-container">
        <input
          type="text"
          value={newItem}
          onChange={(e) => setNewItem(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder={`Add an item (${3 - items.length} remaining)`}
          className={`item-input ${itemError ? 'invalid' : ''}`}
          disabled={items.length >= 3 || isCreating}
        />
        <button
          onClick={handleAddItem}
          className="add-item-button"
          disabled={!newItem.trim() || items.length >= 3 || isCreating}
        >
          +
        </button>
      </div>
      {itemError && <span className="error-message">{itemError}</span>}
      {items.length >= 3 && (
        <span className="info-message">Maximum number of items reached (3)</span>
      )}
    </div>
  );
};

export default UniqueGroupInput; 
