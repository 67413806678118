import React from 'react'
import { ListGroup } from 'react-bootstrap'

function ScoreBoard({ players, roundScores, roundsWon, currentPlayerIndex, turnScore }) {
  return (
    <div>
      <ListGroup>
        {players.map((player, index) => (
          <ListGroup.Item
            key={index}
            variant={index === currentPlayerIndex ? 'primary' : ''}
          >
            <div className="d-flex justify-content-between align-items-center">
              <span>{player}</span>
              <div className="text-end">
                <div>
                  Current Score: {roundScores[player]} 
                  {index === currentPlayerIndex && turnScore > 0 && ` (${turnScore})`}
                </div>
                <div className="small text-muted">
                  Rounds Won: {roundsWon[player] || 0}
                </div>
              </div>
            </div>
          </ListGroup.Item>
        ))}
      </ListGroup>
      <div className="mt-3 text-muted small">
        Note: Lowest score wins each round
      </div>
    </div>
  )
}

export default ScoreBoard

