import React, { useState } from 'react';
import { IoClose } from 'react-icons/io5';
import { useAuth } from '../../context/AuthContext';
import UniqueGroupInput from './UniqueGroupInput';
import { doc, updateDoc, serverTimestamp, arrayUnion } from 'firebase/firestore';
import { db } from '../../firebase/config';
import './GroupModal.css';
import { searchGroupByName } from '../../utils/firebaseUtils';
import { createGroup } from '../../utils/firebaseUtils';

const GroupModal = ({ isOpen, onClose, onGroupAdded, handleShouldRefreshGroups }) => {
  const [activeTab, setActiveTab] = useState('create');
  const [isGroupNameValid, setIsGroupNameValid] = useState(false);
  const [groupName, setGroupName] = useState('');
  const [items, setItems] = useState([]);
  const [joinGroupName, setJoinGroupName] = useState('');
  const [foundGroup, setFoundGroup] = useState(null);
  const [searching, setSearching] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const { user } = useAuth();
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const handleValidityChange = (isValid, name, itemList) => {
    setIsGroupNameValid(isValid);
    setGroupName(name);
    setItems(itemList);
  };

  const handleCreateGroup = async () => {
    if (isCreating) return;
    
    try {
      if (!user) {
        alert('You must be signed in to create a group');
        return;
      }

      setIsCreating(true);
      await createGroup(groupName, items, user.uid);

      setSuccessMessage(`Group "${groupName}" created successfully!`);
      setShowSuccess(true);

      setTimeout(() => {
        setShowSuccess(false);
        onClose();
        setGroupName('');
        setItems([]);
        setIsGroupNameValid(false);
        handleShouldRefreshGroups(true);
      }, 2000);
      
    } catch (error) {
      console.error('Error creating group:', error);
      alert(error.message || 'Failed to create group. Please try again.');
    } finally {
      setIsCreating(false);
    }
  };

  const searchGroup = async () => {
    if (!joinGroupName.trim()) return;
    
    setSearching(true);
    try {
      const group = await searchGroupByName(joinGroupName);
      setFoundGroup(group);
      if (!group) {
        alert('No group found with that name');
      }
    } catch (error) {
      console.error('Error searching for group:', error);
      alert('Failed to search for group');
    } finally {
      setSearching(false);
    }
  };

  const handleJoinGroup = async () => {
    if (!foundGroup || !user) return;

    try {
      const groupRef = doc(db, 'groups', foundGroup.id);
      const userRef = doc(db, 'users', user.uid);
      
      const isAlreadyMember = foundGroup.users.some(u => 
        u.user.id === user.uid || u.user.path === userRef.path
      );
      
      if (isAlreadyMember) {
        alert('You are already a member of this group');
        return;
      }

      const newUserValues = foundGroup.items.map(item => ({
        item: item.ref,
        value: 0
      }));

      const newUser = {
        user: userRef,
        role: 'member',
        values: newUserValues
      };

      await updateDoc(groupRef, {
        users: arrayUnion(newUser),
        updated_at: serverTimestamp()
      });

      onClose();
      setJoinGroupName('');
      setFoundGroup(null);
      
      if (onGroupAdded) {
        onGroupAdded();
      }

      alert('You have joined the group!');
      handleShouldRefreshGroups(true);
    } catch (error) {
      console.error('Error joining group:', error);
      alert('Failed to join group. ' + error.message);
    }
  };

  if (!isOpen) return null;
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={onClose} disabled={isCreating}>
          <IoClose size={24} />
        </button>

        {showSuccess ? (
          <div className="success-message">
            <div className="success-icon">✓</div>
            <p>{successMessage}</p>
          </div>
        ) : (
          <>
            <div className="tab-container">
              <button 
                className={`tab ${activeTab === 'create' ? 'active' : ''}`}
                onClick={() => setActiveTab('create')}
              >
                Create
              </button>
              <button 
                className={`tab ${activeTab === 'join' ? 'active' : ''}`}
                onClick={() => setActiveTab('join')}
              >
                Join
              </button>
            </div>

            {activeTab === 'create' ? (
              <div className="create-container">
                <h2>Create New Group</h2>
                <UniqueGroupInput 
                  onValidityChange={handleValidityChange} 
                  isCreating={isCreating} 
                />
                <button
                  className={`submit-button ${(!isGroupNameValid || items.length === 0 || isCreating) ? 'disabled' : ''}`}
                  onClick={handleCreateGroup}
                  disabled={!isGroupNameValid || items.length === 0 || isCreating}
                >
                  <span className="button-text">
                    {isCreating ? (
                      <div className="loading-spinner">
                        <div className="spinner"></div>
                        Creating...
                      </div>
                    ) : (
                      'Create Group'
                    )}
                  </span>
                  {(!isGroupNameValid || items.length === 0) && !isCreating && (
                    <span className="button-tooltip">
                      {!isGroupNameValid 
                        ? "Please enter a unique group name" 
                        : items.length === 0 
                        ? "Add at least one item" 
                        : ""}
                    </span>
                  )}
                </button>
              </div>
            ) : (
              <div className="join-container">
                <h2>Join Existing Group</h2>
                <div className="search-container">
                  <input
                    type="text"
                    value={joinGroupName}
                    onChange={(e) => setJoinGroupName(e.target.value)}
                    placeholder="Enter group name"
                    className="join-input"
                  />
                  <button
                    className="search-button"
                    onClick={searchGroup}
                    disabled={searching}
                  >
                    {searching ? 'Searching...' : 'Search'}
                  </button>
                </div>

                {foundGroup && (
                  <div className="found-group">
                    <h3>Group Found:</h3>
                    <p className="group-name">{foundGroup.name}</p>
                    <p className="member-count">Members: {foundGroup.users.length}</p>
                    <button 
                      className={`submit-button ${searching ? 'disabled' : ''}`}
                      onClick={handleJoinGroup}
                      disabled={searching}
                    >
                      <span className="button-text">
                        {searching ? 'Joining...' : 'Join Group'}
                      </span>
                    </button>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default GroupModal; 
