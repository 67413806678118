import React, { useState } from 'react'
import { Button, Form, ListGroup, Alert } from 'react-bootstrap'

function PlayerList({ players, addPlayer, startGame }) {
  const [newPlayerName, setNewPlayerName] = useState('')
  const [showMaxPlayersAlert, setShowMaxPlayersAlert] = useState(false)
  
  const MAX_PLAYERS = 6;

  const handleAddPlayer = () => {
    if (players.length >= MAX_PLAYERS) {
      setShowMaxPlayersAlert(true)
      setTimeout(() => setShowMaxPlayersAlert(false), 3000) // Hide alert after 3 seconds
      return
    }

    if (newPlayerName.trim()) {
      addPlayer(newPlayerName.trim())
      setNewPlayerName('')
    }
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      handleAddPlayer()
    }
  }

  return (
    <div>
      <h2 className="mb-3">Players</h2>
      {showMaxPlayersAlert && (
        <Alert 
          variant="warning" 
          className="mb-3"
          onClose={() => setShowMaxPlayersAlert(false)} 
          dismissible
        >
          Maximum {MAX_PLAYERS} players allowed
        </Alert>
      )}
      <ListGroup className="mb-3">
        {players.map((player, index) => (
          <ListGroup.Item key={index}>{player}</ListGroup.Item>
        ))}
      </ListGroup>
      <Form.Group className="mb-3">
        <Form.Control
          type="text"
          value={newPlayerName}
          onChange={(e) => setNewPlayerName(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="Enter player name"
          disabled={players.length >= MAX_PLAYERS}
        />
        <Form.Text className="text-muted">
          {players.length}/{MAX_PLAYERS} players
        </Form.Text>
      </Form.Group>
      <Button 
        variant="primary" 
        onClick={handleAddPlayer} 
        className="me-2"
        disabled={players.length >= MAX_PLAYERS || !newPlayerName.trim()}
      >
        Add Player
      </Button>
      <Button 
        variant="success" 
        onClick={startGame} 
        disabled={players.length < 2}
      >
        Start Game
      </Button>
    </div>
  )
}

export default PlayerList

