import React, { useState , useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase/config';
import Game from './GameComponents/Game';

const Profile = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [firestoreUser, setFirestoreUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      if (!user?.uid) return;
      try {
        setIsLoading(true);
        const userRef = doc(db, 'users', user.uid);
        const userSnap = await getDoc(userRef);
        
        if (userSnap.exists()) {
          setFirestoreUser(userSnap.data());
        }

      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, [user?.uid]);


  // Redirect if not authenticated
  if (!user) {
    navigate('/');
    return null;
  }
  return (
    <div className="profile-container">
      <div className="profile-header">
      {isLoading ? (
        <div className="loader-container">
          <ClipLoader color="#177AD5" size={50} />
        </div>
      ) : (
        <>
          <button className="back-button" onClick={() => navigate('/profile')}>
            ←
          </button>
          <h1>Hi, {firestoreUser?.first_name?.split(" ")[0]}</h1>
        </>
      )}
      </div>
      
      <div className="profile-content">
        <Game />
      </div>
    </div>
  );
};

export default Profile; 
