import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase/config';
import { doc, getDoc, updateDoc, arrayRemove, deleteDoc } from 'firebase/firestore';
import { deleteUser, EmailAuthProvider, reauthenticateWithCredential } from 'firebase/auth';
import Logout from '../auth/logout';
import { ClipLoader } from 'react-spinners';
import './Profile.css';
import { fetchUserGroups, fetchGroupMembers, removeUserFromGroup, deleteGroup, updateGroupActiveStatus } from '../../utils/firebaseUtils';
import dice from '../../assets/dice.png';
import { Form } from 'react-bootstrap';


const Profile = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [firestoreUser, setFirestoreUser] = useState(null);
  const [userGroups, setUserGroups] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [groupMembers, setGroupMembers] = useState({});
  const [selectedMember, setSelectedMember] = useState(null);
  const [isLoadingMembers, setIsLoadingMembers] = useState({});
  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showReauthModal, setShowReauthModal] = useState(false);
  const [password, setPassword] = useState('');
  const [authError, setAuthError] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      if (!user?.uid) return;
      try {
        setIsLoading(true);
        const userRef = doc(db, 'users', user.uid);
        const userSnap = await getDoc(userRef);
        
        if (userSnap.exists()) {
          setFirestoreUser(userSnap.data());
        }

        const userGroupsData = await fetchUserGroups(user.uid);
        setUserGroups(userGroupsData);
        
        const membersData = {};
        await Promise.all(userGroupsData
          .filter(group => group.isAdmin)
          .map(async group => {
            membersData[group.id] = await fetchGroupMembers(group);
          }));
        setGroupMembers(membersData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, [user?.uid]);

  const handleDeleteGroup = async (groupId) => {
    try {
      await removeUserFromGroup(groupId, user.uid);
      setUserGroups(prevGroups => prevGroups.filter(g => g.id !== groupId));
      setShowConfirmModal(false);
      setSelectedGroup(null);
    } catch (error) {
      console.error("Error removing user from group:", error);
    }
  };

  const handleRemoveMember = async (groupId, memberId) => {
    try {
      setIsLoadingMembers(prev => ({ ...prev, [memberId]: true }));
      await removeUserFromGroup(groupId, memberId);
      
      // Update local state to remove the member
      setGroupMembers(prev => ({
        ...prev,
        [groupId]: prev[groupId].filter(member => member.id !== memberId)
      }));
    } catch (error) {
      console.error("Error removing member:", error);
    } finally {
      setIsLoadingMembers(prev => ({ ...prev, [memberId]: false }));
      setShowConfirmModal(false);
      setSelectedMember(null);
    }
  };

  const handleGroupDeletion = async (groupId) => {
    try {
      await deleteGroup(groupId);
      setUserGroups(prevGroups => prevGroups.filter(g => g.id !== groupId));
      setShowConfirmModal(false);
      setSelectedGroup(null);
    } catch (error) {
      console.error("Error deleting group:", error);
    }
  };

  const renderGroupMembers = (group) => {
    if (!group.isAdmin) return null;
    
    const members = groupMembers[group.id] || [];
    const otherMembers = members.filter(member => member.id !== user.uid);
    
    return (
      <div className="group-members" key={`members-${group.id}`}>
        {otherMembers.map(member => (
          <div key={`${group.id}-${member.id}`} className="member-item">
            <div className="member-info">
              <span className="member-name">
                {member.first_name} {member.last_name}
              </span>
              <span className="member-role">{member.role}</span>
            </div>
            <button 
              className="delete-member"
              onClick={() => {
                setSelectedMember({ groupId: group.id, member });
                setShowConfirmModal(true);
              }}
              disabled={isLoadingMembers[member.id]}
            >
              {isLoadingMembers[member.id] ? 
                <ClipLoader color="#ff4444" size={12} /> : 
                '×'
              }
            </button>
          </div>
        ))}
      </div>
    );
  };

  const handleDeleteClick = (group) => {
    setSelectedGroup(group);
    setShowConfirmModal(true);
  };

  const handleReauthenticate = async () => {
    try {
      setAuthError('');
      const credential = EmailAuthProvider.credential(user.email, password);
      await reauthenticateWithCredential(user, credential);
      await handleDeleteAccount();
    } catch (error) {
      console.error("Error re-authenticating:", error);
      setAuthError('Invalid password. Please try again.');
    }
  };

  const handleDeleteAccount = async () => {
    try {
      setIsDeleting(true);
      
      // First, remove user from all their groups
      for (const group of userGroups) {
        const groupRef = doc(db, 'groups', group.id);
        const groupSnap = await getDoc(groupRef);
        
        if (groupSnap.exists()) {
          const groupData = groupSnap.data();
          const userToRemove = groupData.users.find(u => u.user.id === user.uid);
          
          if (userToRemove) {
            await updateDoc(groupRef, {
              users: arrayRemove(userToRemove)
            });
          }
        }
      }

      // Delete user document from Firestore
      const userRef = doc(db, 'users', user.uid);
      await deleteDoc(userRef);

      // Delete user from Firebase Auth
      await deleteUser(user);

      // Clear all browser storage
      localStorage.clear();
      sessionStorage.clear();

      // Clear Google OAuth state
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = 'https://accounts.google.com/logout';
      document.body.appendChild(iframe);
      
      // Remove the iframe after a short delay
      setTimeout(() => {
        document.body.removeChild(iframe);
                
        // Force reload the page to clear all states
        window.location.href = '/';
      }, 1000);

    } catch (error) {
      console.error("Error deleting account:", error);
      if (error.code === 'auth/requires-recent-login') {
        setShowDeleteAccountModal(false);
        setShowReauthModal(true);
      } else {
        alert('Failed to delete account. Please try again.');
      }
    } finally {
      setIsDeleting(false);
      setShowDeleteAccountModal(false);
    }
  };

  const handleActiveToggle = async (group, newValue) => {
    try {
      await updateGroupActiveStatus(group.id, newValue);
      
      // Update local state
      setUserGroups(prevGroups => 
        prevGroups.map(g => 
          g.id === group.id 
            ? { ...g, active: newValue }
            : g
        )
      );
    } catch (error) {
      console.error("Error updating group active status:", error);
      // Optionally add error handling UI feedback here
    }
  };

  // Redirect if not authenticated
  if (!user) {
    navigate('/');
    return null;
  }

  const getUserInitials = (firstName, lastName) => {
    if (!firstName) return '';
    const firstInitial = firstName.charAt(0);
    const lastInitial = lastName ? lastName.charAt(0) : '';
    return `${firstInitial}${lastInitial}`.toUpperCase();
  };

  return (
    <div className="profile-container">
      <div className="profile-header">
        <button className="back-button" onClick={() => navigate('/')}>
          ←
        </button>
        <h1>Hi, {firestoreUser?.first_name?.split(" ")[0]}</h1>
        <button className="dice-button" onClick={() => navigate('/dice')}>
          <img src={dice} alt="Dice" className="dice-icon" />
        </button>
      </div>
      
      <div className="profile-content">
        <div className="profile-photo-container">
          <div className="profile-initials">
            {getUserInitials(firestoreUser?.first_name, firestoreUser?.last_name)}
          </div>
        </div>
        
        <div className="profile-info">
          <p>{firestoreUser?.email}</p>
        </div>

        <div className="groups-list">
          <h2 className="groups-header">My Groups</h2>
          {isLoading ? (
            <div className="loader-container">
              <ClipLoader color="#177AD5" size={50} />
            </div>
          ) : (
            userGroups.map(group => {
              console.log('Group:', group);
              return (
                <div key={`group-${group.id}`} className="group-container">
                  <div className="group-item">
                    <span>{group.name}</span>
                    <div className="group-controls">
                      {group.isAdmin && (
                        <Form.Check
                          type="switch"
                          id={`admin-switch-${group.id}`}
                          label="Active"
                          checked={Boolean(group.active)}
                          onChange={(e) => {
                            console.log('Active toggle changed:', {
                              groupId: group.id,
                              groupName: group.name,
                              newValue: e.target.checked,
                              previousValue: Boolean(group.active)
                            });
                            handleActiveToggle(group, e.target.checked);
                          }}
                          className="admin-toggle"
                        />
                      )}
                      <button
                        className={`delete-group ${group.isAdmin ? 'admin' : ''}`}
                        onClick={() => handleDeleteClick(group)}
                      >
                        {group.isAdmin ? 'DELETE' : 'LEAVE'}
                      </button>
                    </div>
                  </div>
                  {renderGroupMembers(group)}
                </div>
              );
            })
          )}
        </div>
      </div>

      <div className="profile-footer">
        <button 
          className="delete-account-button" 
          onClick={() => setShowDeleteAccountModal(true)}
        >
          Delete Account
        </button>
        <Logout />
      </div>

      {showConfirmModal && (selectedGroup || selectedMember) && (
        <div className="custom-modal-overlay" onClick={() => setShowConfirmModal(false)}>
          <div className="custom-modal-content" onClick={e => e.stopPropagation()}>
            <h3>
              {selectedMember ? 'Remove Member' : 
               (selectedGroup?.isAdmin ? 'Delete Group' : 'Leave Group')}
            </h3>
            <p>
              {selectedMember ? (
                `Are you sure you want to remove ${selectedMember.member.first_name} ${selectedMember.member.last_name} 
                from "${userGroups.find(g => g.id === selectedMember.groupId)?.name}"?`
              ) : (
                selectedGroup?.isAdmin ?
                `Are you sure you want to delete "${selectedGroup.name}"? This action cannot be undone.` :
                `Are you sure you want to leave "${selectedGroup.name}"?`
              )}
            </p>
            <div className="custom-modal-actions">
              <button 
                onClick={() => {
                  if (selectedMember) {
                    handleRemoveMember(selectedMember.groupId, selectedMember.member.id);
                  } else if (selectedGroup?.isAdmin) {
                    handleGroupDeletion(selectedGroup.id);
                  } else {
                    handleDeleteGroup(selectedGroup.id);
                  }
                }}
                className="confirm-button"
              >
                {selectedMember ? 'Yes, Remove Member' : 
                 (selectedGroup?.isAdmin ? 'Yes, Delete Group' : 'Yes, Leave Group')}
              </button>
              <button 
                onClick={() => {
                  setShowConfirmModal(false);
                  setSelectedGroup(null);
                  setSelectedMember(null);
                }}
                className="cancel-button"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {showDeleteAccountModal && (
        <div className="custom-modal-overlay" onClick={() => setShowDeleteAccountModal(false)}>
          <div className="custom-modal-content" onClick={e => e.stopPropagation()}>
            <h3>Delete Account</h3>
            <p>
              Are you sure you want to delete your account? This action cannot be undone and you will lose access to all your groups and data.
            </p>
            <div className="custom-modal-actions">
              <button 
                onClick={handleDeleteAccount}
                className="confirm-button"
                disabled={isDeleting}
              >
                {isDeleting ? 'Deleting...' : 'Yes, Delete My Account'}
              </button>
              <button 
                onClick={() => setShowDeleteAccountModal(false)}
                className="cancel-button"
                disabled={isDeleting}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {showReauthModal && (
        <div className="custom-modal-overlay" onClick={() => setShowReauthModal(false)}>
          <div className="custom-modal-content" onClick={e => e.stopPropagation()}>
            <h3>Confirm Your Password</h3>
            <p>
              For security reasons, please enter your password to continue with account deletion.
            </p>
            {authError && <p className="auth-error">{authError}</p>}
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your password"
              className="password-input"
            />
            <div className="custom-modal-actions">
              <button 
                onClick={handleReauthenticate}
                className="confirm-button"
                disabled={!password || isDeleting}
              >
                {isDeleting ? 'Deleting...' : 'Confirm'}
              </button>
              <button 
                onClick={() => {
                  setShowReauthModal(false);
                  setPassword('');
                  setAuthError('');
                }}
                className="cancel-button"
                disabled={isDeleting}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Profile; 
