import { useState } from 'react';
import { signInWithGoogle } from '../../utils/firebaseUtils';
import { useNavigate } from 'react-router-dom';
import './auth.css';
import logo from '../../assets/landing-logo.png';
import googleLogo from '../../assets/google-g-logo.svg';

function LoginPage() {
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleGoogleSignIn = async () => {
    try {
      await signInWithGoogle();
      navigate('/');
    } catch (error) {
      setError(error.message);
      console.error('Login error:', error);
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <img src={logo} alt="Logo" className="login-logo" />
        {error && <div className="error-message">{error}</div>}
        <button className="google-signin-button" onClick={handleGoogleSignIn}>
          <img 
            src={googleLogo} 
            alt="Google Logo" 
            className="google-icon" 
          />
          Sign in with Google
        </button>
      </div>
    </div>
  );
}

export default LoginPage; 
