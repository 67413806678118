import { ClipLoader } from 'react-spinners';
import './LoadingWrapper.css';

const LoadingWrapper = ({ isLoading, children }) => {
  return (
    <div className="loading-wrapper">
      {children}
      {isLoading && (
        <div className="loader-overlay">
          <ClipLoader color="#ffffff" size={40} />
        </div>
      )}
    </div>
  );
};

export default LoadingWrapper; 
